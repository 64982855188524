<template>
    <div class="reference-letter-point">
        <div class="letter">
            <div class="left">
                <div class="tag">
                    <q-tag size="medium" :variation="letterStatus.variation">{{ $t(`formStatus.${letterStatus.status}`) }}</q-tag>
                </div>
                <p class="name">{{ referenceLetterPoint.form.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { isValidAnswer, executeParentFunction } from '@/assets/js/utils';

export default {
    name: 'reference-letter-point',
    props: {
        point: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            referenceLetterPoint: this.point
        }
    },
    computed: {
        letterStatus: function() {
            const status = this.project.status === 'finished' ? 'finished' : 'active';

            let variation = 'warning';
            if(Boolean(status === 'finished')) variation = 'success'

            return {
                status,
                variation
            }
        },
        canAnswerIndicator() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.referenceLetterPoint);
        },
    },
    watch: {
        point: {
            handler() {
                this.referenceLetterPoint = this.point;
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../../components/qds/assets/style/_variables.scss';
@import '../../../../../components/qds/assets/style/fonts/fonts.css';

.reference-letter-point {
    flex-grow: 1;

    .letter {
        display: flex;
        align-items: center;
        gap: 8px;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;

            .name {
                color: $color-grey-9;
            }
        }

    }
}

</style>