<template>
    <div 
        ref="TimelinePhase" 
        class="timeline-phase" 
        :class="{ 
            'add-margin': phasesContext.index < phasesContext.length-1,
            'closed': collapsed
        }" 
        :style="`--phase-index: ${phasesContext.index}`"
        data-point="phase"
    >
        <div class="phase-wrapper">
            <div class="header" :class="{ pointer: !phase.isFinish }" @click="toggleCollapse">
                <div v-if="phase.isFinish" class="circle-placeholder">
                    <div v-if="phase.able || phase.completed" class="flag-point">
                        <q-icon class="wave-icon" width="18" height="18" type="flag" color="#AEB5BC"></q-icon>
                    </div>
                    <svg v-else class="hekje-svg" width="28" height="18" viewBox="0 0 134 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="111" width="11" height="49" fill="#D9D9D9"/>
                        <rect x="6" width="11" height="87" fill="#D9D9D9"/>
                        <rect x="12" width="10" height="87" fill="#ADB5BD"/>
                        <rect x="117" width="10" height="87" fill="#ADB5BD"/>
                        <path d="M117 44V52L15.5 48L17 44H117Z" fill="#D9D9D9"/>
                        <rect x="117" y="48" width="8" height="100" transform="rotate(90 117 48)" fill="#ADB5BD"/>
                        <path d="M111 56L117 52V87H111V56Z" fill="#D9D9D9"/>
                        <path d="M0.5 8C0.5 7.17157 1.17157 6.5 2 6.5H132C132.828 6.5 133.5 7.17157 133.5 8V31C133.5 31.8284 132.828 32.5 132 32.5H2C1.17157 32.5 0.5 31.8284 0.5 31V8Z" fill="white" stroke="#D9D9D9"/>
                        <path d="M19.4186 7H41L30.5814 32H9L19.4186 7Z" fill="#F03E3E"/>
                        <path d="M62.4186 7H84L73.5814 32H52L62.4186 7Z" fill="#F03E3E"/>
                        <path d="M41.4186 7H63L52.5814 32H31L41.4186 7Z" fill="white"/>
                        <path d="M104.419 7H126L115.581 32H94L104.419 7Z" fill="#F03E3E"/>
                        <path d="M83.4186 7H105L94.5814 32H73L83.4186 7Z" fill="white"/>
                    </svg>
                </div>
                <div v-else class="circle-placeholder">
                    <q-tooltip>
                        <q-circle-diagram :size="22" :width="4" :score="phaseProgressScore" :showScore="false" strokeColor="rgb(253, 126, 23)"></q-circle-diagram>
                        <template #tooltip>
                            <p v-html="phaseTooltip"></p>
                        </template>
                    </q-tooltip>
                </div>

                <div v-if="phase.isFinish" class="finish-phase">
                    <div v-if="phase.completed" class="finished">
                        <p>Het project is {{ organisationType === 'client' ? 'gepubliceerd' : 'afgerond' }}</p>
                    </div>
                    <div v-else class="buttons">
                        <q-button 
                            v-if="phase.able"
                            size="small"
                            @click="handleFinishProject"
                        >{{ organisationType === 'client' ? 'Publiceer het project' : 'Rond het project af' }}</q-button>
                        <p v-else>{{ organisationType === 'client' ? 'Publiceren' : 'Afronden' }} nog niet mogelijk</p>
                    </div>
                </div>

                <div v-else class="left">
                    <p>{{ phase.name }}</p>
                    <q-icon class="chevron" color="#C4C4C4" type="chevronDown"></q-icon>
                </div>

                <div class="right">
                    <q-button 
                        v-if="showGuideButton && !phase.able" 
                        size="small" 
                        variation="ghost" 
                        @click="handleToggleGuide"
                    >Volgende stap</q-button>
                    <jump-transition v-if="showDragButton">
                        <q-icon-button 
                            class="edit-button"
                            :icon="enableDrag ? 'check' : 'drag-icon'" 
                            iconWidth="18" 
                            iconHeight="18" 
                            :iconColor="enableDrag ? 'rgb(55, 179, 78)' : '#484f56'" 
                            @click="handleToggleDragging"
                        ></q-icon-button>
                    </jump-transition>
                    <q-tooltip>
                        <div class="question-circle-bg" :class="[phaseCrowScore.variation, { undefined: phaseCrowScore.score === '?' }]">{{ phaseCrowScore.score }}</div>
                        <template #tooltip>
                            <p v-html="phaseCrowScore.tooltip"></p>
                        </template>
                    </q-tooltip>
                </div>
            </div>

            <collapse-transition v-if="!phase.isFinish" class="collapsable-content">
                <div 
                    v-if="!collapsed"
                    class="line-with-points"
                    :style="`
                        --rows: ${phase.points.length + 1}; 
                        --active-rows: ${activeRows + 1}; 
                        --active-line-height: ${activeLineHeight}px;
                    `">
                    <div class="line"></div>
                    <draggable 
                        :list="phase.points"
                        :group="phase.id"
                        @start="dragging = true"
                        @end="dragging = false"
                        handle=".timeline-point-draggable"
                        ghost-class="ghost-point"
                    >
                        <transition-group id="pointsContainer" name="points-list" class="points-container">
                            <timeline-point
                                v-for="point in phase.points"
                                :key="point.id"
                                :data="point"
                                :project="project"
                                :enableDrag="enableDrag"
                            ></timeline-point>
                        </transition-group>
                    </draggable>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>

<script>
import TimelinePoint from './TimelinePoint.vue';
import draggable from 'vuedraggable';
import { isValidAnswer, executeParentFunction } from '@/assets/js/utils';
import { statusOrder, formStatus } from '../../form/utils';
import _ from 'lodash';

import { PROJECT_UPDATE_PHASE_POINTS_ORDER } from '@/graphql/mutations';

export default {
    name: 'timeline-phase',
    components: {
        TimelinePoint,
        draggable
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            phase: this.data,
            activeLineHeight: 0,
            phasesContext: {
                length: null,
                index: 0
            },
            collapsed: true,
            enableDrag: false,
            dragging: false,
            crowFormCheckpoints: ['concept','open','completed','revision','disagreed','agreed','waitingForAdmin','waitingForCrowAdmin','finished'],
            surveyCheckpoints: ['concept','sent','finished'],
            cachedPointIds: []
        }
    },
    methods: {
        async setActiveLineHeight() {
            if(this.phase.isFinish || this.collapsed) return

            this.$nextTick(() => {
                const container = document.getElementById('pointsContainer');
                const lastActiveChild = container.children[this.activeRows-1];
                const secondLastChild = container.children[container.children.length-2] || container.children[0];

                this.activeLineHeight = lastActiveChild?.offsetTop;
            });
        },
        async toggleCollapse() {
            this.collapsed = !this.collapsed;
            await new Promise(r => setTimeout(r, 100));
            executeParentFunction(this, 'project-timeline', 'setActiveLineHeight');

            if(this.collapsed) this.activeLineHeight = 0;
            else this.setActiveLineHeight();
        },
        handleToggleGuide() {
            executeParentFunction(this, 'project-timeline', 'handleShowNextStep');
        },
        handleToggleDragging() {
            if(this.enableDrag) this.handleSaveOrder();

            this.cachedPointIds = this.phase.points
                .map(point => point.id)
                .filter(id => !id.includes('create'));

            this.enableDrag = !this.enableDrag;
        },
        handleSaveOrder() {
            const pointIds = this.phase.points
                .map(point => point.id)
                .filter(id => !id.includes('create'));

            if(_.isEqual(pointIds, this.cachedPointIds)) return

            this.$apollo.mutate({
                mutation: PROJECT_UPDATE_PHASE_POINTS_ORDER,
                variables: {
                    id: this.project.id,
                    phaseId: this.phase.id,
                    pointIds
                }
            })
            .then(result => {
                this.$store.commit('notify', { type: 'success', message: 'Volgorde succesvol opgeslagen' });
            })
            .catch(error => {
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het opslaan van de volgorde' });
            })
        },
        handleFinishProject() {
            if(this.organisationType === 'client') executeParentFunction(this, 'project', 'publishProject');
            else executeParentFunction(this, 'project', 'finishProject');
        },
        setPhasesContext() {
            if(this.phase.isFinish) return

            const parentElement = this.$refs.TimelinePhase.parentElement;
            const children = [ ...parentElement.children ];
            const points = children.filter(node => node.dataset.point === "phase");
            const index = points.findIndex(point => point.contains(this.$refs.TimelinePhase));
            this.phasesContext = {
                length: points.length,
                index
            };
        }
    },
    computed: {
        activeRows: function() {
            if(this.phase.isFinish) return

            let activeRows = 0;
            this.phase.points.forEach((point, index) => {
                if(point.able && (!point.isCreatePoint || point.isBeingCreated)) activeRows = index+1; 
            })
            if(activeRows === -1) activeRows = this.phase.points.length-1;
            return activeRows
        },
        finishedRows: function() {
            return this.phase.points.filter(point => {
                if(point.type.includes('component')) return isValidAnswer(point.value)
                else if(['form','survey'].includes(point.type)) return point.form.status === 'finished'
            }).length
        },
        phaseProgressScore: function() {
            const pointsToFinish = this.phase.points.filter(point => !point.id.includes('create') && !point.id.includes('skipped'));
            const scorePerPoint = 1 / pointsToFinish.length * 10;

            const totalScore = pointsToFinish.reduce((accumulator, point) => {
                let step = 0;
                if(point.type === 'form') {
                    const { status } = formStatus(point.form);
                    step = (statusOrder.indexOf(status) || 0) / (statusOrder.length-1);
                }
                if(point.type === 'survey') step = (this.surveyCheckpoints.indexOf(point.form.status) || 0) / (this.surveyCheckpoints.length-1);
                if(point.type.includes('component')) step = isValidAnswer(point.value) ? 1 : 0;

                return accumulator += step * scorePerPoint;
            }, 0);

            return totalScore
        },
        phaseCrowScore: function() {
            let score = '?';
            let variation = 'default';
            let tooltip = `Hier komt het eindcijfer van de ${this.$t('phases.'+this.phase.id).toLowerCase()} fase te staan`;

            const scoreDataKey = `${this.organisationType}ScoreData`;
            const hasBouwteamsPhases = this.project.timeline.phases.some(phase => ['design','realisation'].includes(phase.id));

            if(this.phase.isFinish) {
                const finalMeasurements = this.project.forms.filter(form => form.formType === 'bp-final' && form.status === 'finished');
                const scores = finalMeasurements.map(form => form[scoreDataKey]?.average).filter(score => isValidAnswer(score));

                try {
                    if(hasBouwteamsPhases) {
                        const averageKey = `${this.organisationType}Average`
                        score = this.project[averageKey] || '?';
                    }
                    else score = scores[0].toFixed(1) || '?';
                } catch(error) {
                    score = '?';
                }

                if(score !== '?') tooltip = `Het cijfer van dit project is een <b>${score}</b>`;
                else tooltip = 'Hier komt het eindcijfer van het project te staan';

                if(hasBouwteamsPhases) tooltip += ', dit is het<br>gemiddelde van de eindcijfers van beide fases';
            } else {
                const finalMeasurementPoint = this.phase.points.find(point => point.formType === 'bp-final');
                if(finalMeasurementPoint && finalMeasurementPoint.form.status === 'finished') {
                    try {
                        score = finalMeasurementPoint.form[scoreDataKey]?.average.toFixed(1) || '?';
                    } catch(error) {
                        score = '?';
                    }
                    if(score !== '?') {
                        tooltip = `Omdat de eindmeting afgerond is met een <b>${score}</b> is dit<br>het eindcijfer van deze fase. Dit is ook het cijfer wat<br>geregistreerd wordt in de Landelijke Database`;
                    }
                }
            }

            variation = score !== '?' ? score > 5.5 ? 'success' : 'danger' : 'default';

            return {
                score,
                variation,
                tooltip
            }
        },
        phaseTooltip: function() {
            const divider = '<div style="width:100%;height:4px;"></div>';

            let tooltip = `<b>${this.phase.name}</b><br>`;
            let actions = [];

            const relevantPoints = this.phase.points.filter(point => !point.id.includes('create'));
            const phaseIndex = this.project.timeline.phases.findIndex(phase => phase.id === this.phase.id);
            const unfinishedPhaseSurveys = relevantPoints.filter(point => point.type === 'survey' && point.form.status !== 'finished');
            
            if(!this.phase.able) {
                if(phaseIndex !== -1) {
                    const previousPhase = this.project.timeline.phases[phaseIndex-1];
                    tooltip += `De <b>${previousPhase.name}</b> is nog niet afgerond<br>`;
                }
            }
            else if(this.phaseProgressScore !== 10) {
                relevantPoints.forEach(({ reasons = [] }) => actions = [ ...actions, ...reasons ]);
            }
            else tooltip += `De <b>${this.phase.name}</b> is voltooid<br>`;

            if(actions.length > 0) tooltip += 
                `
                    <b>Wat je moet doen om deze fase af te ronden</b><br>
                    • ${actions.join('<br>• ')}<br>
                `;

            if(unfinishedPhaseSurveys.length > 0) {
                tooltip += `Aantal lopende enquetes  <b>${unfinishedPhaseSurveys.length}</b>`;
            }
            else if(this.$store.getters.hasPDFullProduct) {
                tooltip += 'Een enquete starten? Dit kan altijd';
            }

            return tooltip
        },
        showGuideButton: function() {
            return this.project.status !== 'finished' && this.phase.isFinish && !this.phase.able
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType
        },
        showDragButton: function() {
            if(this.phase.isFinish || this.project.status === 'finished') return false

            const hasPointsToDrag = this.phase.points.some(point => !point.id.includes('create') && point.formType === 'survey');
            return !this.collapsed && this.organisationType === 'contractor' && this.$store.getters.hasPDFullProduct && hasPointsToDrag
        }
    },
    watch: {
        data: {
            handler() {
                this.phase = this.data;
            },
            deep: true
        },
        activeRows: function() {
            this.setActiveLineHeight();
        }
    },
    mounted() {
        if(!this.phase.isFinish) {
            if(this.activeRows > 0 && !this.phase.completed) this.collapsed = false;
            this.setActiveLineHeight();
        }

        this.setPhasesContext();
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.timeline-phase {
    --white-space: 48px;
    --header-height: 40px;
    --initial-delay: calc(var(--phase-index) * 100ms + 200ms);

    position: relative;
    z-index: var(--phase-z-index);
    margin-top: var(--padding-block);

    opacity: 0;
    scale: 0.95;

    animation: subtle-jump 250ms ease var(--initial-delay) forwards;
    -webkit-animation: subtle-jump 250ms ease var(--initial-delay) forwards;
    
    &.add-margin {
        margin-bottom: var(--padding-block);
    }

    &.closed .phase-wrapper .header .left .chevron {
        transform: rotate(0deg);
    }

    &:hover {
        z-index: 10;
    }

    .phase-wrapper {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background: white;
        box-shadow: 0px 2px 12px -2px rgba(0, 0, 0, 0.10);

        .circle-placeholder {
            position: absolute;
            left: calc(var(--white-space) / 2);
            top: calc(var(--header-height) / 2);
            transform: translate(-50%, -50%);
            display: grid;
            place-items: center;
            width: 40px;
            height: 40px;
        }
    
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            height: var(--header-height);
            padding-left: var(--white-space);
            padding-right: 12px;
            background: #FAFAFA;
            border-radius: 8px;

            &.pointer {
                cursor: pointer;

                &:hover {
                    background: lighten($color-grey-3, 8%);
                }
            }

            .finish-phase {
                width: 100%;
                
                .buttons {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    gap: 8px;

                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-grey-7;
                }
            }
    
            
            .left {
                display: flex;
                align-items: center;
                gap: 8px;
                
                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: $color-grey-9;
                }

                .chevron {
                    scale: 0.9;
                    transform: rotate(180deg);
                    transform-origin: center 45%;
                    transition: transform 400ms cubic-bezier(.6,0,.2,1);
                    -webkit-transition: transform 400ms cubic-bezier(.6,0,.4,1);
                }
            }
            .right {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.flag-point {
    position: relative;
    background: #FAFAFA;
    border-radius: 50%;

    .wave-icon {
        animation: wave-flag 1800ms cubic-bezier(.7,.1,.3,.92) alternate infinite;
        -webkit-animation: wave-flag 1800ms cubic-bezier(.7,.1,.3,.92) alternate infinite;
        transform-origin: left center;
    }
}

.ghost-point {
    z-index: 10;
    background: transparent;
}

.points-list-move {
  transition: transform 0s;
}

@keyframes subtle-jump {
    from {
        opacity: 0;
        scale: 0.95;
    } to {
        opacity: 1;
        scale: 1;
    }
}
@-webkit-keyframes subtle-jump {
    from {
        opacity: 0;
        scale: 0.9;
    } to {
        opacity: 1;
        scale: 1;
    }
}

@keyframes wave-flag {
    from {
        transform: skew(0deg, -6deg);
    } to {
        transform: skew(0deg, 2deg);
    }
}
@-webkit-keyframes wave-flag {
    from {
        transform: skew(0deg, -6deg);
    } to {
        transform: skew(0deg, 2deg);
    }
}

</style>