<template>
    <div class="indicator-answer">
        <div class="name">
            <p>{{ $t(indicator.name) }}<span style="color:#00a1ae">*</span></p>
        </div>
        <div class="answer" v-if="isEditing">
            <div class="input">
                <q-select-organisation-crow
                    v-model="answer"
                    size="small"
                    :project="project"
                    :indicator="indicator"
                    :type="indicator.masterId === 'contractor-branch' ? 'branch' : 'main'"
                    :organisationType="indicator.name"
                    :isPdProject="indicator.masterId === 'client' && !project.usesCrowFlow"
                    @saveOrganisation="saveOrganisation"
                    @saveContactPerson="saveContactPerson"
                    @saveFromKvk="saveFromKvk"
                    data-testid="test-organisation"
                ></q-select-organisation-crow>
            </div>
        </div>
        <div v-else-if="canEdit.status && !answerIsSet && !loading">
            <q-button
                v-if="indicator.masterId.includes('client') || indicator.masterId.includes('contractor')"
                :variation="indicator.masterId === 'contractor-branch' || !project.usesCrowFlow ? 'ghost' : 'primary'"
                size="small"
                @click="edit"
                v-project-can="'admin__Value__set_value'"
                :disabled="!canEditIndicator"
                >{{ $t(indicator.name) + ' selecteren' }}</q-button
            >
            <q-button
                v-else
                :disabled="!canEditIndicator"
                variation="light"
                size="xsmall"
                @click="edit"
            >Invullen</q-button>
        </div>
        <div class="value" v-else-if="answerIsSet">
            <div v-if="!loadingAnswerValues" class="display" :class="[!canEdit.status ? 'disabled' : null]">
                <p :class="collapsedAnswer ? 'collapsed' : 'opened'" @click="collapsedAnswer = !collapsedAnswer">{{ displayAnswer }}</p>
            </div>
            <div v-else class="loader-wrapper">
                <div class="loader"></div>
            </div>
            <q-tooltip v-if="!loadingAnswerValues" position="top" v-project-can="'admin__Value__set_value'">
                <template #tooltip>
                    <p>{{ canEdit.message }}</p>
                </template>
                <q-icon class="info" type="lock-closed" color="grey" width="10px"></q-icon
            ></q-tooltip>
        </div>
    </div>
</template>

<script>
import { ORGANISATION_SMALL } from '../../../../graphql/queries';
import { SAVE_VALUE_VALUE, PROJECT_ADD_MEMBER, REGISTER_ORGANISATION_BY_KVK, PROJECT_SET_PRIVACY } from '../../../../graphql/mutations';
import { extractError } from '@/assets/js/utils';

export default {
    name: 'crow-indicator-answer',
    props: {
        project: {
            type: Object,
            required: true
        },
        indicator: {
            type: Object,
            required: true
        },
        isEditing: {
            type: Boolean
        },
        loading: {
            type: Boolean
        }
    },
    data() {
        return {
            pickedOrganisation: null,
            answer: null,
            collapsedAnswer: false
        };
    },
    methods: {
        saveContactPerson(userId, email, organisationId) {
            const id = this.project.id;
            const roles = ['admin'];

            this.$apollo
                .mutate({
                    mutation: PROJECT_ADD_MEMBER,
                    variables: {
                        id,
                        userId,
                        email,
                        roles,
                        organisationId
                    }
                })
                .then(result => {
                    const members = result.data.project_addMember.members;
                    this.$emit('updateProject', { members })

                })
                .catch(() => {
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets misgegaan bij het opslaan van de contactpersoon'
                    });
                });
        },
        getProjectJwt() {
            const project = JSON.parse(localStorage.getItem('projectJwt'));
            if(!project || project.id !== this.$route.params.id) return null
            return project.jwt
        },
        saveOrganisation(id, isPrivate) {
            console.log('%csaving organisation: ' + id, 'color: #669900');

            const indicator = this.indicator;
            const organisationId = this.$store.getters.getCurrentOrganisation.id;
            this.answer = id;
            const projectJwt = this.getProjectJwt();

            this.cancel();

            this.$emit('startLoading', indicator);

            this.$apollo
                .query({
                    query: SAVE_VALUE_VALUE,
                    variables: {
                        componentId: indicator.componentId,
                        responseId: this.project.referencesResponseId,
                        formId: this.project.referencesId,
                        indicatorId: indicator.id,
                        value: id,
                        organisationId,
                        projectJwt
                    },
                    fetchPolicy: 'no-cache'
                })
                .then(response => {
                    if (response.data.value_setValue) {
                        this.indicator.answer = response.data.value_setValue.value;
                        this.$emit('saveIndicator', { value: response.data.value_setValue, indicator });
                        if(isPrivate) this.setPrivacy(isPrivate)
                    }
                })
                .catch(err => {
                    this.$emit('stopLoading', this.indicator);
                    this.$store.commit('notify', extractError(err));
                });
        },
        setPrivacy(isPrivate) {
            this.$apollo.mutate({
                mutation: PROJECT_SET_PRIVACY,
                variables: {
                    id: this.project.id,
                    isPrivate
                }
            })
            .then(result => {
                this.$emit('updateProject', { private: isPrivate })
            })
            .catch(error => {
                console.log('project niet prive kunnen zetten!')
            })
        },
        /**
         * Registers a new organisation based on the chosen kvk and adds the contact person as user.
         */
        saveFromKvk(organisation, contactEmail) {
            console.log('%ccreating a new organisation and adding' + contactEmail + ' as user', 'color: #669900');

            const organisationType = this.$store.getters.getOrganisationType;
            const roleId = organisationType === 'client' ? 'crow-admin-default' : 'admin-default';

            this.$emit('startLoading', this.indicator);

            this.$apollo
                .mutate({
                    mutation: REGISTER_ORGANISATION_BY_KVK,
                    variables: {
                        name: organisation.name,
                        address: {
                            line1: organisation.address,
                            zip: organisation.code,
                            city: organisation.city,
                            country: organisation.country
                        },
                        legal: {
                            registrationId: organisation.kvk,
                            branchId: organisation.branch
                        },
                        email: contactEmail,
                        options: {
                            roleId
                        }
                    }
                })
                .then(result => {
                    const organisationId = result.data.org_registerByKvk.id;

                    this.saveOrganisation(organisationId);

                    this.saveContactPerson(undefined, contactEmail, organisationId);
                })
                .catch(() => {
                    this.$emit('stopLoading', this.indicator);
                    this.$store.commit('notify', {
                        type: 'danger',
                        message: 'Er is iets misgegaan bij het kiezen van de organisatie'
                    });
                });
        },
        cancel() {
            this.$emit('cancel');
        },
        edit() {
            this.$emit('edit', this.indicator);
        }
    },
    computed: {
        answerIsSet: function() {
            return this.answer !== null && this.answer !== undefined;
        },
        canEdit: function() {
            if (this.indicator.masterId === 'contractor-branch' && !this.project.contractorId)
                return {
                    status: false,
                    message: 'Opdrachtnemer moet eerst gekozen worden'
                };

            return {
                status: !(
                    ['client', 'contractor', 'contractor-branch', 'crow-type'].includes(this.indicator.masterId) &&
                    this.indicator.answer
                ),
                message: 'U mag dit veld niet aanpassen'
            };
        },
        organisationType: function() {
            return this.$store.getters.getCurrentOrganisation.type
        },
        canEditIndicator: function() {
            return this.$parent.canEditIndicator(this.indicator)
        },
        loadingAnswerValues: function() {
            if(!this.answerIsSet) return false
            const answerValues = this.indicator.answerValues || [];
            return this.answerIsSet && answerValues.length !== 1
        },
        displayAnswer: function() {
            const answerValues = this.indicator.answerValues || [];
            if(!this.answerIsSet || answerValues.length === 0) return ''
            const answer = answerValues.find(answer => answer.value === this.answer)
            if(!answer) return ''
            return answer.label

        }
    },
    watch: {
        indicator: function() {
            this.answer = this.indicator.answer;
        }
    },
    created() {
        this.answer = this.indicator.answer;
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 2.6s;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
    background-size: 600px;
}

@keyframes shine-lines {
    0% {
        background-position: -150px;
    }

    100% {
        background-position: 350px;
    }
}

@keyframes hide {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    } 50% {
        opacity: 1;
    } 90% {
        opacity: 0;
    } 100% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
}

.indicator-answer:hover {
    background-color: lighten($color-grey-3, 5%);

    .value > .tooltip {
        display: inherit;
    }
}


.indicator-answer {
    display: flex;

    margin-left: -8px;
    width: 100%;

    align-items: center;

    min-height: 35px;

    border-radius: 4px;
    margin-left: -12px;
    padding: 4px 12px;

    .name {
        position: relative;
        flex: 0 0 45%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }
    }


    .answer {
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        font-size: $size-s;

        width: 100%;

        display: flex;
    }

    .value {
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex: 0 0 55%;
        width: 100%;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;

        .edit {
            display: none;
        }

        .tooltip {
            display: none;
        }

        .disabled {
            color: lighten($color-grey-7, 20%);
        }

        p {
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            cursor: pointer;
            width: fit-content;

            &.collapsed {
                -webkit-line-clamp: 2;
            }
            &.opened {
                -webkit-line-clamp: unset;
            }
        }
    }

    .input {
        width: 100%;
    }
}

.answer-input {
    width: calc(100% - 16px);
    height: 28px;
    border: 1px solid #00a1ae;
    border-radius: 4px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: white;
    outline: none;
}

.dropdown-input {
    width: 100%;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    width: 100px;

    .loader {
        $loader-color: $color-grey-3;
        $loader-size: 7px;
        $loader-height: 16px;
        $loader-border-size: 6px;
        $loader-gap: 18px;
        $loader-animation-duration: 1.2s;
        @import '@/components/qds/assets/loaders/loaders.scss';
        @include loader12;
    }
}
</style>
