<template>
    <div class="crow-form-point flex align-center space-between">
        <!-- custom components -->
        <div v-if="point.id === 'create-intermediate'" class="flex align-center">
            <div class="button">
                <q-button
                    icon="survey"
                    size="small"
                    variation="ghost"
                    :disabled="!canAnswerIndicator || point.disabled || !point.able || creatingForm"
                    :loading="creatingForm"
                    @click="handleAddForm"
                    >Nieuwe tussentijdse meting</q-button
                >
            </div>
        </div>

        <!-- default crow form -->
        <div v-else class="form flex align-center space-between">
            <div class="left flex align-center">
                <q-tag size="medium" :variation="getFormInfo.variation">{{ getFormInfo.tagText }}</q-tag>
    
                <p v-if="point.form.status === 'concept' && point.able">
                    {{ point.form.name }} Better Performance invullen
                </p>
                <p v-else-if="point.form.status === 'concept' && !point.able">
                    {{ point.form.name }} nog niet beschikbaar
                </p>
                <p v-else-if="!['add_new', 'finalize'].includes(point.form.status)">
                    {{ getFormInfo.text }}
                </p>
            </div>
    
            <!-- right column (options) -->
            <div class="right">
                <q-button
                    v-if="point.form.status === 'skipped'"
                    size="xsmall"
                    variation="ghost"
                    @click="viewSkippedReason = true"
                    >Bekijk toelichting</q-button
                >
                <q-button
                    v-else-if="canSkip"
                    size="xsmall"
                    variation="ghost"
                    @click.stop.prevent="skipRating = true"
                    class="show-on-hover"
                    >Overslaan</q-button
                >
                <q-tooltip v-if="showPublishedIcon" position="top">
                    <template v-slot:tooltip>
                        <p>Opdrachtgever heeft het project gepubliceerd</p>
                    </template>
                    <div class="chart-circle">
                        <q-icon type="chart"></q-icon>
                    </div>
                </q-tooltip>
                <div class="results">
                    <q-pill
                        v-if="showScorePill"
                        :values="getNumberValues"
                        size="medium"
                        interactive
                    ></q-pill>
                    <div v-else>{{ point.result }}</div>
                </div>
            </div>
        </div>


        <!-- skipping form popup -->
        <form-skip-review
            v-if="skipRating"
            :project="project"
            :form="point.form"
            @close="skipRating = false;"
        ></form-skip-review>

        <!-- form is skipped popup -->
        <q-popup v-if="viewSkippedReason && skippedCategory">
            <div class="modal">
                <div class="vertical-center">
                    <h3>Tussentijdse meting overgeslagen</h3>
                </div>
                <div class="modal-content">
                    <p>
                        In dit project is de tussentijdse Better Performance meting overgeslagen op
                        <strong> {{ getDate(skippedCategory.skippedDate) }} </strong> door
                        <strong>{{ skippedCategory.skippedUserId }}</strong>.<br/>
                        Bekijk hieronder de gegeven toelichting.
                    </p><br/>
                    <p style="font-style: italic;">{{ skippedCategory.skippedReason }}</p>

                    <div class="footer">
                        <q-button @click="closePopupViewSkippedReason">Terug naar het project </q-button>
                    </div>
                </div>
            </div>
        </q-popup>
    </div>
</template>

<script>
import FormSkipReview from '@/pages/app/project/FormSkipReview';

import { isDefined, executeParentFunction, getDate } from '@/assets/js/utils';

export default {
    name: 'crow-form-point',
    components: {
        FormSkipReview
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        point: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            viewSkippedReason: false,
            skipRating: false,
            creatingForm: false
        }
    },
    methods: {
        closePopupViewSkippedReason() {
            this.viewSkippedReason = false;
        },
        async handleAddForm() {
            this.creatingForm = true;
            await executeParentFunction(this, 'project', 'clickNewMeasurement', { phaseId: this.point.phaseId });
            this.creatingForm = false;
        },
        getDate(unix) {
            return getDate(unix)
        }
    },
    computed: {
        /**
         * Determines the tag and text to show for every state the form can be in
         * see formStatus.js for documentation about the statusses
        */
        getFormInfo: function() {
            const { formStatus } = require('../../../form/utils');
            const { status } = formStatus(this.point.form);
            let statusLabel = this.$t(`formStatus.${this.point.form.statusLabel}`);
            const text = this.point.formType === 'bp-final' ? 'Eindmeting Better Performance' : 'Tussentijdse Better Performance meting';

            // dit moet netter, formStatus utils werkt hier nog niet mee
            if (this.point.form.status === 'skipped') {
                return {
                    variation: 'info',
                    tagText: statusLabel || 'Overgeslagen',
                    text
                };
            } else if (status === 'concept') {
                return {
                    variation: 'default',
                    tagText: statusLabel || 'Concept',
                    text
                };
            } else if (status === 'startFilling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionToStart') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'filling') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionFill') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'discussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'revision') {
                return {
                    variation: 'danger',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionDiscussion') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCompanionRevision') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'waitingForCrowAdmin') {
                return {
                    variation: 'warning',
                    tagText: statusLabel || 'Actief',
                    text
                };
            } else if (status === 'finished') {
                return {
                    variation: 'success',
                    tagText: statusLabel || 'Afgerond',
                    text
                };
            }

            return false;
        },
        getNumberValues: function() {
            if(!['bp-intermediate','bp-final'].includes(this.point.formType) || this.point.type !== 'form') return

            const form = this.point.form;
            const organisationType = this.$store ? this.$store.getters.getOrganisationType : 'contractor';
            const companionOrganisationType = organisationType === 'client' ? 'contractor' : 'client';
            const scoreGivenToMe = form[`${organisationType}ScoreData`]?.average;
            const scoreGivenToCompanion = form[`${companionOrganisationType}ScoreData`]?.average;
            const companionHasCompletedForm = !['concept', 'open', 'fill'].includes(form.companions[0]?.status);
            const youHaveCompletedForm = !['concept', 'open', 'fill'].includes(form.status);
            const youHaveResponse = ['open', 'fill'].includes(form.status) && form.responseIds?.length !== 0;
            const companionHasResponse = ['open', 'fill'].includes(form.companions[0]?.status) && form.companions[0]?.responseIds?.length !== 0;
            const yourFormSkipped = organisationType === 'contractor' && form.responses?.length === 0 && form.status === 'finished';
            const companionFormSkipped = organisationType === 'client' && form.status === 'finished' && form.companions[0]?.responseIds?.length === 0;
            const youInRevision = form.status === 'disagreed' || (form.status === 'pending_crow_admin' && form.companions[0]?.status === 'crow_admin_disagreed');
            const companionInRevision = form.companions[0]?.status === 'disagreed' || form.status === 'crow_admin_disagreed';

            const greyColor = '#f4f5f6';
            const greenColor = '#cdecd2';
            const redColor = '#fce1e2';

            const you = organisationType === 'client' ? 'Opdrachtgever' : 'Opdrachtnemer';
            const companion = organisationType === 'client' ? 'Opdrachtnemer' : 'Opdrachtgever';

            let yourText = '';
            let yourTooltip = '';
            let yourColor = greyColor;
            let companionText = '';
            let companionTooltip = '';
            let companionColor = greyColor;

            if(yourFormSkipped) {
                yourText = '>';
                yourTooltip = `Geen score, eenzijdig afgerond door ${you.toLowerCase()}`;
            }
            else if(youInRevision) {
                yourText = '?';
                yourTooltip = 'Het formulier is in revisie';
                yourColor = redColor;
            }
            else if(youHaveCompletedForm && isDefined(scoreGivenToMe)) {
                yourText = scoreGivenToMe.toFixed(1);
                yourTooltip = `Behaalde score ${you.toLowerCase()}`;
                if(scoreGivenToMe > 5.5) yourColor = greenColor;
            }
            else if(youHaveCompletedForm && !isDefined(scoreGivenToMe)) {
                yourText = '?';
                yourTooltip = 'Het formulier is vastgesteld';
                yourColor = greenColor;
            }
            else if(youHaveResponse) {
                yourText = '?';
                yourTooltip = `Het formulier is nog niet vastgesteld`;
            }
            else {
                yourText = '-';
                yourTooltip = `Het formulier moet nog ingevuld worden`;
            }

            if(companionFormSkipped) {
                companionText = '>';
                companionTooltip = `Geen score, eenzijdig afgerond door ${companion.toLowerCase()}`;
            }
            else if(companionInRevision) {
                companionText = '?';
                companionTooltip = 'Het formulier is in revisie';
                companionColor = redColor;
            }
            else if((youHaveCompletedForm || youInRevision) && companionHasCompletedForm && isDefined(scoreGivenToCompanion)) {
                companionText = scoreGivenToCompanion.toFixed(1);
                companionTooltip = `Behaalde score ${companion.toLowerCase()}`;
                if(scoreGivenToCompanion > 5.5) companionColor = greenColor;
            }
            else if(companionHasCompletedForm) {
                companionText = '?'
                companionTooltip = `${companion} heeft al vastgesteld`;
                companionColor = greenColor;
            }
            else if (companionHasResponse) {
                companionText = '?';
                companionTooltip = `${companion} heeft nog niet vastgesteld`;
            }
            else {
                companionText = '-';
                companionTooltip = `${companion} moet formulier nog ingevullen`;
            }

            return [
                {
                    text: yourText,
                    color: yourColor,
                    tooltip: yourTooltip
                },
                {
                    text: companionText,
                    color: companionColor,
                    tooltip: companionTooltip
                }
            ];
        },
        canSkip: function() {
            const { pdHybridmode, contractor, organisationId } = this.project;
            const currentOrganisationId = this.$store.getters.getCurrentOrganisation.id;
            const initiator = pdHybridmode
                ? currentOrganisationId === contractor?.id
                : currentOrganisationId === organisationId;
            const isOnlyIntermediateMeasurement = !this.project.timeline.points.some(point => point.phaseId === this.point.phaseId && point.formType === 'bp-intermediate' && point.id !== this.point.id);
            const hasNoResponses = (!this.point.form.responseIds?.length && !this.point.form.companions[0]?.responseIds?.length);

            const canSkip = this.point.able && 
                this.point.form.status === 'concept' && 
                this.point.formType === 'bp-intermediate' && 
                initiator && 
                isOnlyIntermediateMeasurement && 
                hasNoResponses;

            return canSkip
        },
        showScorePill: function() {
            if(!['bp-intermediate','bp-final'].includes(this.point.formType) || this.point.type !== 'form') return false
            return this.point.able && this.point.form.status !== 'concept';
        },
        canAnswerIndicator: function() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.point);
        },
        skippedCategory: function() {
            const phase = this.project.timeline.phases.find(phase => phase.pointIds.includes(this.point.id));
            const category = phase.categories.find(category => category.skipped);
            return category
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType
        },
        showPublishedIcon: function() {
            const isFinalMeasurement = this.point.formType === 'bp-final';

            return this.project.clientStatus === 'finished' && isFinalMeasurement
        }
    }
}
</script>

<style lang="scss" scoped>

.crow-form-point {
    width: 100%;

    .form {
        width: 100%;

        .left {
            gap: 8px;
        }
    
        .right {
            display: flex;
            align-items: center;
            gap: 12px;

            .show-on-hover {
                opacity: 0;
                transition: 300ms ease;
            }
        }
    }
}

.crow-form-point:hover {
    .form .right .show-on-hover {
        opacity: 1;
    }
}

.modal {
    width: 500px;
    display: flex;
    flex-direction: column;

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px 0 0 0;
    }
}

.modal-content {
    margin: 20px 0 0 0;
}

</style>