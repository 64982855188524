<template>
    <div class="page">
        <focus-guide ref="FocusGuide"></focus-guide>
        <PortalTarget class="tooltip-portal" name="tooltip" />
        <PortalTarget class="dropdown-portal" name="dropdown" />
        <PortalTarget class="popup-portal" name="popup" multiple />
        <PortalTarget class="tokens-portal" name="tokens" multiple />
        <router-view />
    </div>
</template>

<script>
import { updateDropdownPanels } from '@/assets/js/utils.js';
import _ from 'lodash';
import { productFruits } from 'product-fruits'
import FocusGuide from '@/components/FocusGuide.vue';

export default {
    name: 'app',
    components: {
        FocusGuide
    },
    data() {
        return {
            user: {
                id: 'before-login-user'
            },
            role: 'none'
        }
    },
    methods: {
        emitMouseMove: _.debounce(function(vm, event) {
            vm.$root.$emit('mousemove', event);
        }, 4),
        emitScroll(vm, event) {
            vm.$root.$emit('scroll', event);
        },
        handleUserUpdated() {
            const keys = Object.keys(this.$store.getters.getUser);
            if(keys.length > 0) this.user = this.$store.getters.getUser;
        },
        handleOrganisationUpdated() {
            const organisation = this.$store.getters.getCurrentOrganisation;
            if(!organisation) return
            this.role = this.getProductFruitsSlugs(organisation);

            productFruits.safeExec(($productFruits) => {
                window.productFruits.identifyUser({ username: this.user.id, props: { slugs: this.role } });
            });
        },
        getProductFruitsSlugs(organisation) {
            const { products = [] } = organisation;
            const activeProducts = products.filter(product => product.enabled);
            const activeSlugs = activeProducts.map(product => product.slug);
            const relevantSlugs = activeSlugs.filter(slug => ['emvi_client','emvi_contractor','crow_client','crow_contractor','pd_basis','pd_full','crow_hybrid'].includes(slug))
            return relevantSlugs.join(',')
        },
        async openMyChatWidget() {
            const script = document.createElement("script");
            script.src = '/statics/trengoScript.js';
            script.onload = () => {
                window.Trengo.render = true;

                window.Trengo.on_ready = function() {
                    window.Trengo.Api.Widget.open('email');

                    window.Trengo.on_close = function() {
                        window.Trengo.render = false;
                        window.productFruits.api.inAppCenter.show();

                        const frame = document.getElementById('trengo-widget');
                        const button = document.getElementById('trengo-web-widget');
                        document.head.removeChild(script);
                        document.body.removeChild(frame);
                        document.body.removeChild(button);
                    };
                }
            }
            document.head.appendChild(script);
        },
        newFocusGuide(params) {
            this.$refs.FocusGuide.startNewGuide(params);
        }
    },
    created() {
        const user = this.$store.getters.getUser;
        if(Object.keys(user).length > 0) this.user.id = user.id;

        productFruits.init(process.env.PRODUCT_FRUITS_WORKSPACE_CODE, 'nl', { username: this.user.id, props: { slugs: this.role } });

        window.addEventListener('mousemove', (event) => {
            this.emitMouseMove(this, event);
        })
        window.addEventListener('scroll', (event) => {
            this.emitScroll(this, event);
            updateDropdownPanels(this);
        }, true)

        window.addEventListener('productfruits_button_ext_widget_init', () => {
            window.productFruits.api.inAppCenter.close();
            this.openMyChatWidget();
        }, true);
        
        this.handleUserUpdated();
        this.handleOrganisationUpdated();

        this.$store.commit('setLastRoutingEvent', { from: this.$route.path, to: this.$route.path });
    },
    watch: {
        '$store.getters.getUser': function() {
            this.$nextTick(() => this.handleUserUpdated());
        },
        '$route.fullPath': function(to, from) {
            this.$store.commit('setLastRoutingEvent', { from, to });
        }
    }
}
</script>

<style lang="scss">
@import './components/qds/assets/style/style.scss';

html, body {
    overscroll-behavior: none;
}

.tooltip-portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 106;
    pointer-events: none;
}

.dropdown-portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 105;
    pointer-events: none;
}

.popup-portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 104;

    &:empty {
        pointer-events: none;
    }
}
.tokens-portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 105;
    pointer-events: none;
}

body {
    margin: 0;
    min-height: 100vh;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.row {
    margin: 0 !important;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.mini-tag {
    height: 18px;
    padding: 0px 10px;
}

.fade-in {
    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
    animation: fade-in 280ms forwards ease-in;
}

/* Gutter calculation */
@for $i from 1 through 12 {
    .gutter:first-child:nth-last-child(#{$i}),
    .gutter:first-child:nth-last-child(#{$i}) ~ .gutter {
        flex: 0 0 calc(100% / #{$i} - 1.2em);
    }
}

.organisation-item #fade::before {
    background-image: none !important;
}

// utility classes (to be moved to design system)

.ml-xxs {
    margin-left: 2px;
}

.ml-xs {
    margin-left: 4px;
}

.ml-s {
    margin-left: 8px;
}

.ml-m {
    margin-left: 16px;
}

.ml-l {
    margin-left: 24px;
}

.ml-xl {
    margin-left: 40px;
}

.ml-xxl {
    margin-left: 48px;
}

// Timeline
ul.timeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;

    &::before {
        content: '';
        width: 1px;
        background-color: #adb5bd;
        position: absolute;
        top: 32px;
        bottom: 32px;
        left: 18px;
        z-index: 1;
    }

    li {
        padding: 20px 20px 20px 40px;
        border-radius: 4px;
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            background: #eef0f1;

            .on-hover {
                display: inline;
            }
        }

        &::after {
            content: '';
            background: #adb5bd;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;

            top: 32px;
            left: 15px;
        }

        .on-hover {
            display: none;
        }
    }
}

// Element UI Datepicker overrides

.el-picker-panel {
    color: #606266;
    border: 1px solid #e4e7ed;
    font-family: 'Gotham', sans-serif;
    line-height: 30px;
    margin: 5px 0;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: $color-primary !important;
}

.el-date-table td.current:not(.disabled) span {
    background-color: $color-primary !important;
}

.el-date-table td.today span {
    color: $color-primary !important;
}

.el-date-table td.available:hover {
    color: $color-primary !important;
}

.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
    color: $color-primary !important;
}

.el-picker-panel__icon-btn:hover {
    color: $color-primary !important;
}

.el-month-table td .cell:hover {
    color: $color-primary !important;
}

.el-year-table td.today .cell {
    color: $color-primary !important;
}

.el-year-table td .cell:hover,
.el-year-table td.current:not(.disabled) .cell {
    color: $color-primary !important;
}

.el-date-table td.end-date span,
.el-date-table td.start-date span {
    background-color: $color-primary !important;
}

button,
label,
*:focus {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>
