<template>
    <div class="project-timeline">
        <div class="header flex space-between">
            <div class="left">
                <h3>Project tijdlijn</h3>
            </div>
            <div class="right">
                <p>Aangemaakt op {{ getDate(project.creationDate) }}</p>
            </div>
        </div>

        <div 
            class="timeline-wrapper line-with-points"
            :style="`
                --rows: ${timelinePoints.length + 1}; 
                --active-rows: ${activeRows + 2}; 
                --active-line-height: ${activeLineHeight}px;
            `">
            <div class="line"></div>
            <div id="phasesContainer" class="points-container">
                <component
                    v-for="point in timelinePoints"
                    :key="point.id"
                    class="timeline-point"
                    :is="`timeline-${point.isPhase ? 'phase' : 'point'}`"
                    :data="point"
                    :project="project"
                    :class="{ phase: point.isPhase }"
                ></component>
            </div>
        </div>
    </div>
</template>

<script>
import { getNumericDate, isValidAnswer, isDefined, executeParentFunction } from '@/assets/js/utils';
import { v4 as uuidv4 } from 'uuid';

import TimelinePhase from './TimelinePhase.vue';
import TimelinePoint from './TimelinePoint.vue';

export default {
    name: 'project-timeline',
    components: {
        TimelinePhase,
        TimelinePoint
    },
    props: {
        project: {
            type: Object,
            required: true
        },
        formTemplates: {
            type: Array,
            default() { return [] }
        },
        canFinish: {
            type: Boolean,
            default: false
        },
        canAdminAgree: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            projectTimeline: {
                points: [],
                phases: []
            },
            timelinePoints: [],
            activeRows: 0,
            activeLineHeight: 0
        }
    },
    methods: {
        getDate(unix) {
            return getNumericDate(unix)
        },
        async setActiveLineHeight() {
            // if(this.activeLineHeight > 0) await new Promise(r => setTimeout(r, 400));
            
            this.$nextTick(() => {
                const container = document.getElementById('phasesContainer');
                const lastActiveChild = container.children[this.activeRows];
                const secondLastChild = container.children[this.activeRows-1] || container.children[0];
                
                this.activeLineHeight = lastActiveChild?.offsetTop;
            });
        },
        getPointState(point, phase) {
            const canFinish = {
                status: null,
                reasons: [],
                elementIds: []
            };

            const { dependsOn = [] } = point;
            const phasePoints = this.project.timeline.points.filter(point => phase.pointIds.includes(point.id));
            const dependingOnPoint = dependsOn.find(pointId => !this.validatePoint(pointId));
            const bpForms = this.project.forms.filter(form => ['bp-intermediate','bp-final'].includes(form.formType));
            const hasActiveForms = bpForms.some(form => form.responseIds?.length > 0 || form.companions[0]?.responseIds?.length > 0);

            // old forms did not require discussed-psu to be filled before starting bp forms
            if(dependingOnPoint && !(dependingOnPoint === 'discussed-psu' && hasActiveForms)) {
                canFinish.status = false;
                canFinish.reasons.push('Vul in of het startgesprek is besproken');
                canFinish.elementIds.push(`timeline-point-${dependingOnPoint}-${phase.id}`);
            }

            const categoryState = this.getPointCategoryState(point, phase.categories);
            if(!isDefined(canFinish.status)) canFinish.status = categoryState.canFinish.status;
            canFinish.reasons = [ ...canFinish.reasons, ...categoryState.canFinish.reasons ];
            canFinish.elementIds = [ ...canFinish.elementIds, ...categoryState.canFinish.elementIds ];

            if(['survey','referenceletter'].includes(point.type)) canFinish.status = true;

            return {
                canFinish,
                startedNextCategory: categoryState.startedNextCategory
            }
        },
        validatePoint(pointId) {
            const point = this.project.timeline.points.find(point => point.id === pointId);

            if(['form','survey'].includes(point.type)) return point.form.status === 'finished'
            if(point.type.includes('component')) return isValidAnswer(point.value)
            if(point.type === 'referenceletter') return true

            console.warn('invalid point type for point', pointId)
            return false
        },
        getPointCategoryState(point, categories = []) {
            const defaultValue = {
                canFinish: {
                    status: true,
                    reasons: [],
                    elementIds: []
                }, 
                startedNextCategory: false
            };
            if(!categories || !Array.isArray(categories) || categories.length === 0) return defaultValue

            const categoryIndex = categories.findIndex(category => category.pointIds.includes(point.id));
            if(categoryIndex === -1) return defaultValue

            let { status, reasons = [] } = this.categoryDependanciesMet(categories, categoryIndex);
            const elementIds = [];
            const startedNextCategory = this.hasStartedNextCategory(categories, categoryIndex);

            if(point.type === 'form') {
                if(point.form.status !== 'finished') {
                    reasons.push(`Rond de ${point.form.name.toLowerCase()} af`);
                    elementIds.push(`timeline-point-${point.id}-${point.phaseId}`);
                } else {
                    status = true;
                }
            }

            return {
                canFinish: {
                    status,
                    reasons,
                    elementIds
                },
                startedNextCategory
            }
        },
        /**
         * This method checks if all dependancies are met for the category to be able to
         * add forms returns a status with boolean if all dependant requirements are
         * met and an array of reasons if not valid
         */
        categoryDependanciesMet(categories, categoryKey) {
            let able = [];
            let reasons = [];

            const category = categories[categoryKey];

            if (category.skipped) {
                return {
                    status: true,
                    reasons: []
                }
            }

            if (category.dependsOn) {
                category.dependsOn.forEach((dependant) => {
                    let dependantKey = categories.map((category) => category.id).indexOf(dependant.id);

                    dependant.category = categories[dependantKey];

                    if(!dependant.category.skipped) {
                        let validCount = 0;
                        let allFinished = true;
    
                        dependant.category.pointIds.forEach((pointId) => {
                            const point = this.project.timeline.points.find(point => point.id === pointId);
                            const isBpPoint = ['bp-intermediate', 'bp-final'].includes(point?.formType);
                            if(isBpPoint) {
                                if(point.form.status !== 'finished') allFinished = false;
                                if(dependant.status.includes(point.form.status)) validCount++;
                            }
                        });
    
                        able.push(validCount >= dependant.amount && (dependant.category.allFinished || allFinished));
                    }
                });
            }

            return {
                status: able.every((dependant) => dependant),
                reasons,
            };
        },
        /**
         * returns status with a boolean for if its finished and reasons with an array of the categories
         * if it hasnt finished yet
         */
        categoryIsFinished(category) {
            const { finishProject, pointIds = [] } = category;
            if (!finishProject) return true;
            
            const forms = pointIds
                .map(pointId => this.project.timeline.points.find(point => point.id === pointId)?.form)
                .filter(form => form);

            const crowForms = forms.filter(form => ['client', 'contractor'].includes(form.type));
            const surveys = forms.filter(form => !['client', 'contractor'].includes(form.type));

            if (!this.project.usesCrowFlow) {
                if (forms.length === 0) return true;
                return !surveys.some(form => form.status !== 'finished');
            }

            const categoryCrowFormsFinished = crowForms.every(form => form.status === 'finished');
            return categoryCrowFormsFinished;
        },
        hasStartedNextCategory(categories, categoryKey) {
            const nextCategory = categories[categoryKey + 1];
            if (!nextCategory) return false;

            const points = this.project.timeline.points.filter(point => nextCategory.pointIds.includes(point.id));
            const formPoints = points.filter(point => ['bp-intermediate','bp-final'].includes(point.formType));
            const startedForms = formPoints.some(point => {
                if(point.form.status !== 'concept' || point.form.responseIds?.length > 0) return true 
                const companion = point.form.companions ? point.form.companions[0] : null;
                return companion?.responseIds?.length > 0
            });

            return startedForms;
        },
        setProjectTimeline() {
            const { phases, points } = this.project.timeline;

            let lastCompletedPhaseIndex = -1;
            let lastPointIndex = 0;
            const phasesWithPoints = phases.map((phase, phaseIndex) => {
                const phasePoints = points.filter(point => phase.pointIds.includes(point.id));
                const parsedPoints = phasePoints.map(point => {
                    const pointState = this.getPointState(point, phase);
                    let { status: able, reasons, elementIds } = pointState.canFinish;
                    
                    if(lastCompletedPhaseIndex < phaseIndex-1 && !['survey','referenceletter'].includes(point.type)) able = false;
                    lastPointIndex++;

                    return {
                        ...point,
                        able,
                        startedNextCategory: pointState.startedNextCategory,
                        reasons,
                        elementIds,
                        index: lastPointIndex
                    }
                });

                const completed = parsedPoints.every(point => point.type === 'form' ? point.able && !point.isCreatePoint && point.form.status === 'finished' : true);
                if(completed) lastCompletedPhaseIndex++;

                return {
                    ...phase,
                    able: lastCompletedPhaseIndex === phaseIndex+1,
                    points: parsedPoints,
                    completed
                }
            });

            this.projectTimeline = {
                phases: phasesWithPoints, 
                points
            };
        },
        setTimelinePoints() {
            let timelinePoints = [];
            for(let i = 0; i < this.projectTimeline.phases.length; i++) {
                const phase = this.projectTimeline.phases[i];
                const points = this.parsePhasePoints(phase);
                let able = points.some(point => point.able && !point.isCreatePoint);
                if(!able && timelinePoints.length > 0) able = timelinePoints[timelinePoints.length-1].completed;

                if(phase.display === 'points') {
                    timelinePoints = [ ...timelinePoints, ...points ];
                    continue;
                }

                timelinePoints.push({
                    isPhase: true,
                    ...phase,
                    able,
                    points
                });
            };

            this.timelinePoints = timelinePoints;

            const { status, reason } = this.organisationType === 'client' ? this.canPublish : this.canFinalize;
            
            timelinePoints.push({
                id: 'finish-point',
                isPhase: true,
                isFinish: true,
                able: this.canFinish && status,
                completed: this.project.status === 'finished',
                type: 'finish'
            });

            this.timelinePoints = timelinePoints;

            this.updateTimelineState();
        },
        parsePhasePoints(phase) {
            const hasBPActive = this.project.forms.some(form => ['bp-intermediate','bp-final'].includes(form.formType));
            if(
                (phase.id === 'initial' && hasBPActive) ||
                phase.id === 'reference-letter'
            ) return phase.points

            const projectFinished = this.project.status === 'finished';
            const hasPDFull = this.$store.getters.hasPDFullProduct;
            const isInitiator = this.$store.getters.getCurrentOrganisation.id === this.project.organisationId;
            const { usesCrowFlow } = this.project;
            let points = phase.points;
            
            const lastIntermediateMeasurementIndex = points.findLastIndex(point => point.formType === 'bp-intermediate');
            const lastIntermediateMeasurement = points[lastIntermediateMeasurementIndex];
            const canStartIntermediateMeasurement = lastIntermediateMeasurement?.form?.status === 'finished' && !lastIntermediateMeasurement.startedNextCategory;

            if(!projectFinished && isInitiator && usesCrowFlow && canStartIntermediateMeasurement) {
                const createIntermediatePoint = {
                    id: `create-intermediate`,
                    type: 'form',
                    phaseId: phase.id,
                    able: true,
                    tooltip: 'Het is mogelijk om meerdere tussentijdse metingen op te<br>nemen wanneer de eindmeting nog in concept staat',
                    reasons: [],
                    index: lastIntermediateMeasurementIndex
                };
                points.splice(lastIntermediateMeasurementIndex+1, 0, createIntermediatePoint);
            }

            if(!projectFinished && this.organisationType === 'contractor' && hasPDFull) {
                const options = this.formTemplates.map(template => {
                    return {
                        label: template.name,
                        value: template.id,
                        disabled: template.disabled,
                        tooltip: template.tooltip
                    }
                });
                const hasOptionsAvailable = options.some(option => !option.disabled);

                const createSurveyPoint = {
                    id: `create-survey-${phase.id}`,
                    isCreatePoint: true,
                    type: 'survey',
                    phaseId: phase.id,
                    form: {
                        name: '',
                        status: 'concept',
                        template: {
                            name: ''
                        }
                    },
                    options,
                    able: hasOptionsAvailable,
                    reasons: !hasOptionsAvailable ? ['Dit project bevat geen vragen, voeg ze toe om enquetes aan te maken'] : [],
                    index: phase.points.length
                }

                points.push(createSurveyPoint);
            }

            return points
        },
        updateTimelineState() {
            if(this.project.status === 'finished') return this.setActiveRows(this.timelinePoints.length-1);
            let activeRows = this.timelinePoints.findIndex(point => 
                !(point.able && 
                    !(point.id.includes('create') && 
                    point.type !== 'referenceletter')
                )
            )-1;
            if(activeRows < 0) activeRows = this.timelinePoints.length-1;
            this.setActiveRows(activeRows);
        },
        setActiveRows(amount) {
            this.activeRows = amount;
            this.setActiveLineHeight();
        },
        handlePointUpdated(point) {
            const points = [ ...this.timelinePoints ];
            this.timelinePoints = points.map(timelinePoint => {
                if(timelinePoint.id === point.id) return point
                if(!timelinePoint.isPhase || timelinePoint.isFinish) return timelinePoint

                const pointIds = timelinePoint.points.map(phasePoint => phasePoint.id);
                if(!pointIds.includes(point.id)) return timelinePoint

                timelinePoint.points = timelinePoint.points.map(phasePoint => {
                    if(phasePoint.id !== point.id) return phasePoint
                    return point
                });

                return timelinePoint
            });
            
            // this.setProjectTimeline();
            this.updateTimelineState();
        },
        handlePointAdded(point) {
            const { phaseId } = point;
            const points = [ ...this.timelinePoints ];
            if(!phaseId) return this.timelinePoints.splice(this.timelinePoints.length, 0, point)

            this.timelinePoints = points.map(timelinePoint => {
                if(!timelinePoint.isPhase || timelinePoint.isFinish || timelinePoint.id !== phaseId) return timelinePoint

                const createPointIndex = timelinePoint.points.findIndex(point => point.id.includes('create-survey'));

                if(createPointIndex !== -1) timelinePoint.points[createPointIndex] = point;
                else timelinePoint.points.splice(timelinePoint.points.length, 0, point);

                return timelinePoint
            });

            this.updateTimelineState();
        },
        handlePointRemoved(point) {
            const { phaseId } = point;
            const phase = this.project.timeline.phases.find(phase => phase.id === phaseId);
            const points = [ ...this.timelinePoints ];
            
            if(phase.display === 'points') {
                const index = points.findIndex(timelinePoint => timelinePoint.id === point.id);
                this.timelinePoints.splice(index, 1);
            }
            else this.timelinePoints = points.map(timelinePoint => {
                if(!timelinePoint.isPhase || timelinePoint.isFinish || timelinePoint.id !== phaseId) return timelinePoint

                const index = timelinePoint.points.findIndex(timelinePoint => timelinePoint.id === point.id);
                if(index !== -1) timelinePoint.points.splice(index, 1);

                return timelinePoint
            });

            this.updateTimelineState();
        },
        handleShowNextStep() {
            const { status, reason, elementId } = this.organisationType === 'client' ? this.canPublish : this.canFinalize;
            const element = document.getElementById(elementId);
            if(elementId && element) this.handleFocusNextStep([elementId], reason);
            else this.$store.commit('notify', { type: 'info', message: reason });
        },
        async handleFocusNextStep(elementIds, reason) {
            let hasFocused = false;
            for(let i = 0; i < elementIds.length; i++) {
                const pointElement = document.getElementById(elementIds[i]);
                if(!pointElement) continue;
                if(!hasFocused) {
                    hasFocused = true;
                    executeParentFunction(this, 'app', 'newFocusGuide', { elementId: pointElement.id, description: reason });
                }
            }
        },
        getFirstReason() {
            let firstReason = {
                reason: '',
                elementId: ''
            }
            let allPoints = [];

            this.timelinePoints.forEach(point => {
                if(point.points) allPoints = [ ...allPoints, ...point.points ];
                else allPoints.push(point);
            });

            for(let i = 0; i < allPoints.length; i++) {
                const point = allPoints[i];
                const reasons = point.reasons || [];
                if(point.reasons[0]) {
                    firstReason.reason = point.reasons[0];
                    if(point.elementIds[0]) firstReason.elementId = point.elementIds[0];
                }

                if(firstReason.reason) break;
            }

            return firstReason
        }
    },
    computed: {
        /**
         * Calculates whether finalized can be emited based on given forms
        */
        canPublish: function () {
            if (this.project.status === 'finished')
                return {
                    status: true,
                };
            let canPublish = [];

            if (!this.project.hasChosenCrowFlow && this.useBPReference)
                return {
                    status: false,
                    reason: 'Kies of je Better Performance toe wilt passen in dit project',
                    elementId: 'timeline-point-crow-point-initial'
                };

            let crowInitialised = false;
            let BPForms = this.project.timeline.points.filter(point => point.type === 'form');
            this.timelinePoints.forEach((point) => {
                if((point.isPhase || point.phaseId === 'crow') && !point.isFinish && point.type !== 'referenceletter') {
                    if(!crowInitialised) {
                        const phase = this.project.timeline.phases.find(phase => phase.id === 'crow');
                        point.categories = phase?.categories || [];
                        crowInitialised = true;
                    }
                    const categories = point.categories || [];
                    categories.forEach(category => {
                        const canFinish = this.categoryIsFinished(category);
                        canPublish.push(canFinish);
                    });
                }
            });

            if (!canPublish.every((able) => able)) {
                const { reason, elementId } = this.getFirstReason();
                return {
                    status: false,
                    reason,
                    elementId
                };
            } else if (this.project.usesCrowFlow && !this.canFinish)
                return {
                    status: false,
                    reason: 'Je kan het project pas publiceren als alle verplichte projectgegevens- en kenmerken zijn ingevuld',
                };
            else if (this.organisationType !== 'client' && !this.project.private && this.project.usesCrowFlow) {
                return {
                    status: false,
                    reason: 'Alleen de opdrachtgever kan dit project publiceren omdat dit een actie is voor de CROW Beheerder',
                };
            } else if (this.project.usesCrowFlow && !this.canAdminAgree.able) {
                return {
                    status: false,
                    reason: this.canAdminAgree.reason,
                };
            }

            return {
                status: true,
            };
        },
        canFinalize: function () {
            let canFinalize = [];
            if (this.project.status === 'finished')
                return {
                    status: true,
                };

            let BPForms = this.project.timeline.points.filter(point => point.type === 'form');
            const activeSurveys = this.project.timeline.points.filter(point => point.formType === 'survey' && point.form.status !== 'finished');
            this.timelinePoints.forEach((point) => {
                const phaseId = point.phaseId;
                const phase = this.project.timeline.phases.find(phase => phase.id === phaseId);
                const isPointsPhase = phase?.display === 'points';

                if((!point.isPhase || point.isFinish || point.type === 'referenceletter') && !isPointsPhase) return

                let categories = isPointsPhase ? phase.categories : point.categories;
                if(!categories) categories = [];

                categories.forEach(category => {
                    const canFinish = this.categoryIsFinished(category);
                    canFinalize.push(canFinish);
                });
            });

            const isProjectAdmin = this.projectAbility.roles.includes('admin');

            // niet checken op product maar op heeft crow-point
            if (!this.project.hasChosenCrowFlow && this.$store.getters.hasActiveQfactProducts)
                return {
                    status: false,
                    reason: 'Kies of je Better Performance toe wilt passen in dit project',
                    elementId: 'timeline-point-crow-point-initial'
                };
            else if (this.project.usesCrowFlow && BPForms.length === 0)
                return {
                    status: false,
                    reason: 'Vul de Better Performance kenmerken in om een meting te starten',
                };
            else if (!canFinalize.every((able) => able)) {
                const { reason, elementId } = this.getFirstReason();
                return {
                    status: false,
                    reason,
                    elementId
                };
            } else if (!this.canFinish) {
                return {
                    status: false,
                    reason: 'Je kan het project pas afronden als alle verplichte projectgegevens- en kenmerken zijn ingevuld',
                };
            } else if (activeSurveys.length > 0) {
                return {
                    status: false,
                    reason: 'Je kan het project pas afronden als alle openstaande enquetes zijn afgerond',
                };
            } else if (!isProjectAdmin) {
                return {
                    status: false,
                    reason: 'Alleen een beheerder kan dit project afronden'
                }
            }

            return {
                status: true,
            };
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType
        }
    },
    watch: {
        project: {
            handler() {
                this.setProjectTimeline();
                this.setTimelinePoints();
                this.updateTimelineState();
            },
            deep: true
        },
        formTemplates: {
            handler() {
                this.setProjectTimeline();
                this.setTimelinePoints();
                this.updateTimelineState();
            },
            deep: true
        }
    },
    created() {
        this.setProjectTimeline();
        this.setTimelinePoints();
    }
}
</script>

<style lang="scss">
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.project-timeline {
    flex-grow: 1;
    user-select: none;

    .header {
        .right {
            p {
                color: $color-grey-5;
            }
        }
    }
}

.line-with-points {
    --phase-z-index: 5;
    --point-z-index: 3;
    --line-z-index: 2;
    --placeholder-line-z-index: 1;
    --white-space: 48px;
    --point-diameter: 8px;
    --padding-block: 8px;
    --min-height: 44px;
    --point-position-top: calc(var(--min-height) / 2 + var(--padding-block) - var(--point-diameter) / 2);

    position: relative;

    .line {
        position: absolute;
        top: calc(var(--point-position-top) + var(--point-diameter) / 2);
        bottom: calc(var(--point-position-top) + var(--point-diameter) / 2);
        left: calc(var(--white-space) / 2);
        pointer-events: none;
        width: 1px;
        margin-left: -0.5px;
        overflow: hidden;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 1px;
        }

        &:before {
            height: calc(var(--active-line-height, 0));
            background: $color-grey-5;
            transition: height 1.5s cubic-bezier(.29,.54,.07,.97) 400ms;
            z-index: var(--line-z-index);
        }
        
        &:after {
            top: 0;
            bottom: 100%;
            --svg-pixel-height: 5px;
            background: url('/statics/img/rounded-dashed-line-pattern.svg');
            background-repeat: infinite;
            animation: 
                draw-line 1s cubic-bezier(.7,.05,.31,.95) forwards,
                animate-dotted-line 1s linear infinite;
            -webkit-animation: 
                draw-line 1s cubic-bezier(.7,.05,.31,.95) forwards,
                animate-dotted-line 1s linear infinite;
            z-index: var(--placeholder-line-z-index);
        }
    }

    .points-container {
        display: flex;
        flex-direction: column;
    }
}

@keyframes draw-line {
    to {
        bottom: 0;
    }
}
@-webkit-keyframes draw-line {
    to {
        bottom: 0;
    }
}

@keyframes animate-dotted-line {
    from {
        background-position: 0 0;
    } to {
        background-position: 0 5px;
    }
}
@-webkit-keyframes animate-dotted-line {
    from {
        background-position: 0 0;
    } to {
        background-position: 0 5px;
    }
}

.timeline-wrapper {
    margin-top: 18px;
    // overflow: hidden;
}

.question-circle-bg {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #e0e3e5;
    
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #484f56;

    &.undefined {
        width: 28px;
        height: 28px;
    }

    &.warning {
        background: $color-orange-lighter;
        color: $color-orange-dark;
    }
    &.success {
        background: $color-green-lighter;
        color: $color-green-dark;
    }
    &.danger {
        background: $color-red-lighter;
        color: $color-red-dark;
    }
}

</style>